export const MODALS = {
  LOGIN_MODAL: 'LOGIN_MODAL',
  SIGNUP_MODAL: 'SIGNUP_MODAL',
  UPDATE_LOGIN_MODAL: 'UPDATE_LOGIN_MODAL',
  CONFIRM_EMAIL_MODAL: 'CONFIRM_EMAIL_MODAL',
  REGISTRATION_DETAILS_MODAL: 'REGISTRATION_DETAILS_MODAL',
  CAMPAIGN_FAQ_LIST_MODAL: 'CAMPAIGN_FAQ_LIST_MODAL',
  SHARE_SOCIAL_MEDIA: 'SHARE_SOCIAL_MEDIA',
  EARLY_BIRD: 'EARLY_BIRD',
  CAMPAIGN_STATUS_MODAL: 'CAMPAIGN_STATUS_MODAL',
  VERIFY_USER: 'VERIFY_USER',
  FAQ_MODAL_FUNDRAISER: 'FAQ_MODAL_FUNDRAISER',
  WARNING: 'WARNING',
};
