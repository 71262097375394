import React from 'react';
import { makeStyles } from '@mui/styles';

import { Modal } from '@vizsla/components';
import { useAppAuth, useModal, useNotification } from '@vizsla/hooks';

import { MODALS } from 'src/constants/modals';

import { CheckUserStatusByEmailForm } from './CheckUserStatusByEmailForm';
import { ResetPasswordAndVerifyCodeForm } from './ResetPasswordAndVerifyCodeForm';

const useStyles = makeStyles({
  modalWrapper: {
    '& .MuiDialog-container': {
      justifyContent: 'center',
    },
    '& .MuiDialog-paper': {
      width: '400px',
      maxWidth: '600px',
      maxHeight: 'unset',
      margin: 0,
      padding: '1%',
    },
    '& .MuiPaper-root': {
      display: 'block',
    },
    '& .MuiDialogContent-root': {
      overflowY: 'auto',
      scrollbarWidth: 'auto',
      maxHeight: '50vh',
    },
  },
  signupText: {
    color: 'white',
  },
  textContainer: {
    margin: 'auto !important',
  },
  text2ndContainer: {
    marginLeft: 'auto !important',
    marginRight: 'auto !important',
    marginTop: '-10px',
  },
  resendButton: {
    marginTop: '-10px',
  },
  inputFont: {
    '& input': {
      fontSize: 'clamp(12px, 2vw, 16px)',
    },
  },
});

export type ForgotPasswordState = {
  email: string;
  isEmailSent: boolean;
  confirmed: boolean;
};

export const UpdatePasswordModal: React.FC = () => {
  const classes = useStyles();
  const { error: showError, success: showSuccess } = useNotification();
  const {
    forgotPasswordCodeVerification,
    forgotPassword,
    checkUserStatus,
    confirmEmail,
    resendCodeconfirmEmail,
  } = useAppAuth();
  const { isOpen, closeModal, openModal, args } = useModal(MODALS.UPDATE_LOGIN_MODAL);
  const [{ isEmailSent, email, confirmed }, setForgotPasswordState] =
    React.useState<ForgotPasswordState>({
      email: '',
      isEmailSent: false,
      confirmed: false,
    });

  const onSendCode = React.useCallback(
    async ({ email }) => {
      const status = await checkUserStatus(
        email,
        process.env.REACT_APP_USER_STATUS_CHECKER_TOKEN ?? '',
      );

      if (status.exists) {
        if (status.status === 'CONFIRMED') {
          await forgotPassword(email);
          setForgotPasswordState({ email, isEmailSent: true, confirmed: true });
        } else if (status.status === 'UNCONFIRMED') {
          await resendCodeconfirmEmail({ email });
          openModal(MODALS.CONFIRM_EMAIL_MODAL, { email, disableEmail: true });
        }
      } else {
        showError('The email address entered does not match any account in the system.');
      }
    },
    [checkUserStatus, forgotPassword, openModal, resendCodeconfirmEmail, showError],
  );

  const resendCode = React.useCallback(() => {
    forgotPassword(email);
    showSuccess('Code resent successfully');
  }, [email, forgotPassword, showSuccess]);

  const closePasswordModal = React.useCallback(() => {
    setForgotPasswordState({
      email: '',
      isEmailSent: false,
      confirmed: false,
    });
    closeModal();
    args?.onClose?.();
  }, [args, closeModal]);

  const onResetPassword = React.useCallback(
    async ({ email, code, password }) => {
      await forgotPasswordCodeVerification({ email, code, password });
      showSuccess('Password updated successfully');
      closePasswordModal();
      openModal(MODALS.LOGIN_MODAL, args);
    },
    [forgotPasswordCodeVerification, showSuccess, closePasswordModal, openModal, args],
  );

  const backToLogin = (reset: (initialValues?: Partial<any> | undefined) => void) => {
    reset();
    closeModal();
    setForgotPasswordState({
      email: '',
      isEmailSent: false,
      confirmed: false,
    });
    openModal(MODALS.LOGIN_MODAL, args);
  };

  const backToChangeEmail = React.useCallback(() => {
    setForgotPasswordState({
      email: '',
      isEmailSent: false,
      confirmed: false,
    });
  }, []);

  return (
    <React.Fragment>
      <Modal isOpen={isOpen} onClose={closePasswordModal} customClasses={[classes.modalWrapper]}>
        <Modal.Title>Update Login</Modal.Title>
        <Modal.Content>
          {!isEmailSent && (
            <CheckUserStatusByEmailForm
              classes={classes}
              onSubmit={onSendCode}
              onBackToLogin={backToLogin}
            />
          )}
          {isEmailSent && confirmed && (
            <ResetPasswordAndVerifyCodeForm
              classes={classes}
              email={email}
              onSubmit={onResetPassword}
              onResendCode={resendCode}
              onBackChangeEmail={backToChangeEmail}
              onBackToLogin={backToLogin}
            />
          )}
        </Modal.Content>
      </Modal>
    </React.Fragment>
  );
};
