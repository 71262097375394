import React from 'react';
import { Form } from 'react-final-form';
import { makeStyles } from '@mui/styles';
import { Grid, Typography, InputAdornment } from '@mui/material';
import { CreateOutlined, MailOutlined } from '@mui/icons-material';

import { useModal, useAppAuth, useNotification } from '@vizsla/hooks';
import { PaletteColor, Shade } from '@vizsla/theme';
import { Field, Modal, TextField } from '@vizsla/components';

import { MODALS } from 'src/constants/modals';
import { AuthSubmitButton } from 'src/components/auth';

const useStyles = makeStyles({
  modalWrapper: {
    '& .MuiDialog-container': {
      justifyContent: 'center',
    },
    '& .MuiDialog-paper': {
      width: '400px',
      maxWidth: '600px',
      maxHeight: 'unset',
      margin: 0,
      padding: '1%',
    },
    '& .MuiPaper-root': {
      display: 'block',
    },
    '& .MuiDialogContent-root': {
      overflowY: 'auto',
      scrollbarWidth: 'auto',
      maxHeight: '50vh',
    },
  },
  loginForm: {
    marginRight: 'auto',
  },
  signupText: {
    display: 'flex',
    color: Shade.Gray[400],
    justifyContent: 'center',
  },
  textContainer: {
    margin: 'auto !important',
  },
  inputFont: {
    '& input ': {
      fontSize: 'clamp(12px, 2vw, 16px)',
    },
    '& .Mui-disabled': {
      '-webkit-text-fill-color': 'rgba(0, 0, 0, 0.38) !important',
      color: 'rgba(0, 0, 0, 0.38) !important',
    },
    '& .MuiInputLabel-root.Mui-disabled': {
      color: 'rgba(0, 0, 0, 0.38)',
    },
    '& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline': {
      borderColor: 'rgba(0, 0, 0, 0.12)',
    },
  },
});

export const ConfirmEmailModal: React.FC = () => {
  const classes = useStyles();
  const { isOpen, closeModal, openModal, args } = useModal(MODALS.CONFIRM_EMAIL_MODAL);
  const { confirmEmail, resendCodeconfirmEmail } = useAppAuth();
  const notification = useNotification();

  const initialValues = {
    email: args?.email,
  };

  const onLogin = (reset: (initialValues?: Partial<any> | undefined) => void) => {
    reset();
    closeModal();
    openModal(MODALS.LOGIN_MODAL);
  };

  const onSubmit = React.useCallback(
    async data => {
      try {
        await confirmEmail(data);
        notification.success('Email confirmed successfully');
        closeModal();
        openModal(MODALS.LOGIN_MODAL);
      } catch (error) {
        if (
          error.code === 'NotAuthorizedException' &&
          error.message === 'User cannot be confirmed. Current status is CONFIRMED'
        ) {
          notification.error('The user has already been confirmed');
        } else if (error.code === 'CodeMismatchException') {
          notification.error('The verification code is incorrect.');
        } else if (error.code === 'ExpiredCodeException') {
          notification.error('The verification code has expired.');
        } else if (error.code === 'UserNotFoundException') {
          notification.error('User not found.');
        } else {
          notification.error('Error confirming registration');
        }
        closeModal();
      }
    },
    [closeModal, confirmEmail, openModal],
  );

  const resendCode = React.useCallback(
    async data => {
      const email = data.getState().values.email;
      try {
        await resendCodeconfirmEmail({ email });
        notification.success('Resended Code');
      } catch (error) {
        if (
          error.code === 'NotAuthorizedException' &&
          error.message === 'User cannot be confirmed. Current status is CONFIRMED'
        ) {
          notification.error('The user has already been confirmed');
        } else if (error.code === 'CodeMismatchException') {
          notification.error('The verification code is incorrect.');
        } else if (error.code === 'ExpiredCodeException') {
          notification.error('The verification code has expired.');
        } else if (error.code === 'UserNotFoundException') {
          notification.error('User not found.');
        } else {
          notification.error('Error confirming registration');
        }
        closeModal();
      }
    },
    [closeModal, confirmEmail, openModal],
  );

  return (
    <Modal isOpen={isOpen} onClose={closeModal} customClasses={[classes.modalWrapper]}>
      <Modal.Title>Confirm your Email</Modal.Title>
      <Modal.Content>
        <Form onSubmit={onSubmit} initialValues={initialValues}>
          {({ handleSubmit, submitting, pristine, hasValidationErrors, form }) => {
            const isDisabled = submitting || pristine || hasValidationErrors;

            return (
              <form onSubmit={handleSubmit} className={classes.loginForm} autoComplete="off">
                <Grid container direction="column" spacing={3}>
                  <Grid item xs={12} paddingLeft={0}>
                    <Grid
                      container
                      spacing={0}
                      margin={0}
                      xs={12}
                      justifyContent="center"
                      direction="column"
                    >
                      <Grid item paddingTop={2} marginTop={2} marginBottom={2}>
                        <Field
                          name="email"
                          label="Email Address"
                          component={TextField}
                          e2e-id="email"
                          disabled={args?.disableEmail}
                          InputProps={{
                            placeholder: 'Email Address',
                            startAdornment: (
                              <InputAdornment position="start">
                                <MailOutlined />
                              </InputAdornment>
                            ),
                          }}
                          className={classes.inputFont}
                          fullWidth
                        />
                      </Grid>
                      <Grid item paddingTop={2}>
                        <Field
                          name="code"
                          label="Code"
                          component={TextField}
                          e2e-id="code"
                          autoComplete="nope"
                          InputProps={{
                            placeholder: 'Code',
                            startAdornment: (
                              <InputAdornment position="start">
                                <CreateOutlined />
                              </InputAdornment>
                            ),
                          }}
                          className={classes.inputFont}
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} paddingLeft={0}>
                    <Grid container spacing={1}>
                      <Grid item xs={6} paddingLeft={0}>
                        <AuthSubmitButton
                          type="button"
                          loading={submitting}
                          disabled={isDisabled}
                          size="small"
                          onClick={() => resendCode(form)}
                        >
                          Resend Code
                        </AuthSubmitButton>
                      </Grid>
                      <Grid item xs={6} paddingLeft={0}>
                        <AuthSubmitButton
                          type="submit"
                          loading={submitting}
                          disabled={isDisabled}
                          e2e-id="submit"
                          size="small"
                        >
                          Confirm
                        </AuthSubmitButton>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} className={classes.signupText}>
                    <Typography className={classes.signupText} component="span">
                      Already have an Account?&nbsp;
                    </Typography>
                    <Typography
                      style={{ color: PaletteColor.TealText, cursor: 'pointer' }}
                      component="span"
                      onClick={() => onLogin(form.reset)}
                    >
                      Log In
                    </Typography>
                  </Grid>
                </Grid>
              </form>
            );
          }}
        </Form>
      </Modal.Content>
    </Modal>
  );
};
