import React, { useEffect } from 'react';
import { CircularProgress } from '@mui/material';

import { Button, DonationForm, DonationFormValues, Form, ShoppingCart } from '@vizsla/components';
import { DonationFrecuency } from '@vizsla/types';
import { getNowDate, getUserFullName, toDateTimeFromISO } from '@vizsla/utils';
import { useExperienceLandingQuery } from '@vizsla/graphql';

import { useCurrentOrganization } from 'src/hooks/organization';
import { SupportPageLayout } from 'src/layouts';
import { PurchaseDescriptorHeader } from 'src/components';

import { Buttons, Content, LoadingContent } from './FundraiserDonation.style';
import { useTeamFundraisingDonate } from './useFundraiserDonate';

const currentDate = String(toDateTimeFromISO(getNowDate()));
const FORM_INITIAL_VALUES: DonationFormValues = {
  frecuency: DonationFrecuency.ONE_TIME,
  startDate: currentDate,
};

export function FundraiserDonation() {
  const { fundraiser, fetching: loading, validateForm, handleSubmit } = useTeamFundraisingDonate();
  const experienceId = fundraiser?.attendee?.experience?.id ?? undefined;
  const { organization } = useCurrentOrganization();
  const { data: experienceData } = useExperienceLandingQuery({
    variables: { id: fundraiser?.attendee?.experience?.id as string },
    fetchPolicy: 'cache-first',
  });
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (loading || !fundraiser) {
    return (
      <SupportPageLayout>
        <SupportPageLayout.Content>
          <LoadingContent>
            <CircularProgress size="5rem" />
          </LoadingContent>
        </SupportPageLayout.Content>

        <SupportPageLayout.Sidebar>
          <ShoppingCart />
        </SupportPageLayout.Sidebar>
      </SupportPageLayout>
    );
  }

  return (
    <SupportPageLayout>
      <SupportPageLayout.Content>
        <PurchaseDescriptorHeader
          subtitle="You are donating to"
          title={getUserFullName(fundraiser?.attendee?.user) ?? undefined}
        />
        <p style={{ fontSize: '14px', width: '100%', textAlign: 'center' }}>
          This donation benefits{' '}
          {experienceData?.experience?.fundraisingSettings?.donationSettings
            ?.benefittingCharitiesOrganizationName || organization?.name}
        </p>
        <Form initialValues={FORM_INITIAL_VALUES} validate={validateForm} onSubmit={handleSubmit}>
          {form => (
            <Content>
              <DonationForm
                experienceId={experienceId}
                organizationId={organization?.id as string}
              />

              <Buttons>
                <Button
                  loading={loading}
                  onClick={form.handleSubmit}
                  disabled={loading || form.invalid}
                >
                  Continue
                </Button>
              </Buttons>
            </Content>
          )}
        </Form>
      </SupportPageLayout.Content>

      <SupportPageLayout.Sidebar>
        <ShoppingCart />
      </SupportPageLayout.Sidebar>
    </SupportPageLayout>
  );
}
