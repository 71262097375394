import React, { useMemo } from 'react';
import { CircularProgress } from '@mui/material';

import { Button, DonationForm, DonationFormValues, Form, ShoppingCart } from '@vizsla/components';
import { DonationFrecuency } from '@vizsla/types';
import { getNowDate, toDateTimeFromISO } from '@vizsla/utils';
import { useExperienceLandingQuery } from '@vizsla/graphql';

import { useCurrentOrganization } from 'src/hooks/organization';
import { SupportPageLayout } from 'src/layouts';
import { PurchaseDescriptorHeader } from 'src/components';

import { Buttons, Content, LoadingContent } from './TeamFundraisingDonate.style';
import { useTeamFundraisingDonate } from './useTeamFundraisingDonate';

const currentDate = String(toDateTimeFromISO(getNowDate()));
const FORM_INITIAL_VALUES: DonationFormValues = {
  frecuency: DonationFrecuency.ONE_TIME,
  startDate: currentDate,
};

export function TeamFundraisingDonate() {
  const { team, fetching: loading, validateForm, handleSubmit } = useTeamFundraisingDonate();
  const experienceId = useMemo(() => {
    return team?.experience?.id ?? undefined;
  }, [team]);
  const { data: experienceData } = useExperienceLandingQuery({
    variables: { id: team?.experience?.id as string },
    fetchPolicy: 'cache-first',
  });
  const { organization } = useCurrentOrganization();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const initialValues = useMemo(
    () => ({
      ...FORM_INITIAL_VALUES,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [team],
  );

  if (loading || !team) {
    return (
      <SupportPageLayout>
        <SupportPageLayout.Content>
          <LoadingContent>
            <CircularProgress size="5rem" />
          </LoadingContent>
        </SupportPageLayout.Content>

        <SupportPageLayout.Sidebar>
          <ShoppingCart />
        </SupportPageLayout.Sidebar>
      </SupportPageLayout>
    );
  }

  return (
    <SupportPageLayout>
      <SupportPageLayout.Content>
        <PurchaseDescriptorHeader subtitle="You are donating to" title={team?.name ?? undefined} />
        <p style={{ fontSize: '14px', width: '100%', textAlign: 'center' }}>
          This donation benefits{' '}
          {experienceData?.experience?.fundraisingSettings?.donationSettings
            ?.benefittingCharitiesOrganizationName || organization?.name}
        </p>
        <Form initialValues={initialValues} validate={validateForm} onSubmit={handleSubmit}>
          {form => (
            <Content>
              <DonationForm
                experienceId={experienceId}
                organizationId={organization?.id as string}
              />

              <Buttons>
                <Button
                  loading={loading}
                  onClick={form.handleSubmit}
                  disabled={loading || form.invalid}
                >
                  Continue
                </Button>
              </Buttons>
            </Content>
          )}
        </Form>
      </SupportPageLayout.Content>

      <SupportPageLayout.Sidebar>
        <ShoppingCart />
      </SupportPageLayout.Sidebar>
    </SupportPageLayout>
  );
}
