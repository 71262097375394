import React from 'react';
import { Form } from 'react-final-form';
import { makeStyles } from '@mui/styles';
import { Checkbox, FormControlLabel, Grid, Theme, Typography } from '@mui/material';

import { useModal, useAppAuth } from '@vizsla/hooks';
import { PaletteColor, Shade } from '@vizsla/theme';
import { Field, Modal, TextField } from '@vizsla/components';
import { COGNITO_ERROR_CODES } from '@vizsla/constants';

import { MODALS } from 'src/constants/modals';
import { AuthSubmitButton } from 'src/components/auth';

const useStyles = makeStyles((theme: Theme) => ({
  modalWrapper: {
    '& .MuiDialog-container': {
      justifyContent: 'center',
    },
    '& .MuiDialog-paper': {
      width: '400px',
      height: 'auto',
      maxWidth: '600px',
      maxHeight: 'unset',
      margin: 0,
      padding: '1%',
    },
    '& .MuiPaper-root': {
      display: 'block',
    },
    '& .MuiDialogContent-root': {
      overflowY: 'auto',
      scrollbarWidth: 'auto',
      maxHeight: 'unset',
    },
  },
  loginForm: {
    marginRight: 'auto',
  },
  signupTextContainer: {
    marginTop: theme.spacing(4),
  },
  signupText: {
    color: Shade.Gray[400],
  },
  externalLink: {
    textDecoration: 'none',
    color: 'rgb(0, 159, 176)',
  },
  submitButton: {
    margin: theme.spacing(2.5, 0, 0, 0),
  },
  checkBoxLabel: {
    '& .MuiFormControlLabel-label': {
      color: PaletteColor.BlackText,
    },
  },
  checkBox: {
    color: Shade.Gray[400],
    '&.Mui-checked': {
      color: Shade.Teal[600],
    },
  },
  textContainer: {
    margin: 'auto !important',
  },
  inputFont: {
    '& input': {
      fontSize: 'clamp(12px, 2vw, 16px)',
    },
  },
}));
interface SignUpDialogProps {
  onComplete?: () => void;
  onClose?: () => void;
}

type SignupErrorType = keyof typeof COGNITO_ERROR_CODES;

export const SignupModal: React.FC = () => {
  const classes = useStyles();

  const { isOpen, closeModal, openModal, args } = useModal<SignUpDialogProps>(MODALS.SIGNUP_MODAL);

  const { register } = useAppAuth();

  const [isAgreedToTerms, setAgreedToTerms] = React.useState<boolean>(false);

  const handleChangeAgreed = () => setAgreedToTerms(!isAgreedToTerms);

  const closeSignUpModal = () => {
    closeModal();
    args?.onClose?.();
  };

  const onLogin = (reset: (initialValues?: Partial<any> | undefined) => void) => {
    reset();
    closeModal();
    openModal(MODALS.LOGIN_MODAL, args);
  };

  const onSubmit = React.useCallback(
    async data => {
      try {
        await register(data);
        closeModal();
        args?.onComplete?.();
        return openModal(MODALS.CONFIRM_EMAIL_MODAL, {
          email: data?.email,
        });
      } catch (e) {
        const errorCode: SignupErrorType = e?.code;
        const errorField =
          COGNITO_ERROR_CODES[errorCode]?.field || COGNITO_ERROR_CODES.UnknownError.field;
        const errorMessage = e.message ?? 'Incorrect email';
        return {
          [errorField]: errorMessage,
        };
      }
    },
    [args, closeModal, openModal, register],
  );

  return (
    <Modal isOpen={isOpen} onClose={closeSignUpModal} customClasses={[classes.modalWrapper]}>
      <Modal.Title>Create an Account</Modal.Title>
      <Modal.Content>
        <Form
          onSubmit={onSubmit}
          initialValues={{
            firstName: '',
            lastName: '',
            email: '',
            password: '',
            confirmPassword: '',
          }}
        >
          {({ handleSubmit, submitting, pristine, hasValidationErrors, form }) => {
            const isDisabled = submitting || pristine || !isAgreedToTerms || hasValidationErrors;

            return (
              <form onSubmit={handleSubmit} autoComplete="off">
                <Grid container direction="column" spacing={3}>
                  <Grid item xs={12} paddingLeft={0}>
                    <Grid container spacing={0} xs={12} justifyContent="center" direction="column">
                      <Grid item marginTop={2} marginBottom={2}>
                        <Typography variant="body1">Signup with Email</Typography>
                      </Grid>
                      <Grid item paddingTop={2}>
                        <Field
                          name="firstName"
                          label="First Name"
                          component={TextField}
                          e2e-id="firstName"
                          fullWidth
                          className={classes.inputFont}
                        />
                      </Grid>
                      <Grid item paddingTop={2}>
                        <Field
                          name="lastName"
                          label="Last Name"
                          component={TextField}
                          e2e-id="lastName"
                          fullWidth
                          className={classes.inputFont}
                        />
                      </Grid>
                      <Grid item paddingTop={2}>
                        <Field
                          name="email"
                          label="Email Address"
                          component={TextField}
                          e2e-id="email"
                          autoComplete="nope"
                          fullWidth
                          className={classes.inputFont}
                        />
                      </Grid>
                      <Grid item paddingTop={2}>
                        <Field
                          name="password"
                          label="Password"
                          component={TextField}
                          type="password"
                          e2e-id="password"
                          autoComplete="nope"
                          fullWidth
                          className={classes.inputFont}
                        />
                      </Grid>
                      <Grid item paddingTop={2}>
                        <Field
                          name="confirmPassword"
                          label="Confirm Password"
                          component={TextField}
                          type="password"
                          e2e-id="password"
                          autoComplete="nope"
                          fullWidth
                          className={classes.inputFont}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sx={{
                          marginTop: '1.5rem',
                        }}
                      >
                        <FormControlLabel
                          className={classes.checkBoxLabel}
                          label={
                            <Typography style={{ color: PaletteColor.DefaultText }}>
                              I agree to the
                              <a
                                href="https://www.colliegives.com/terms-of-service"
                                target="_blank"
                                rel="noopener noreferrer"
                                className={classes.externalLink}
                              >
                                &nbsp;Collie Terms{' '}
                              </a>{' '}
                              and{' '}
                              <a
                                href="https://www.colliegives.com/privacy-policy"
                                target="_blank"
                                rel="noopener noreferrer"
                                className={classes.externalLink}
                              >
                                Privacy Policy.
                              </a>
                            </Typography>
                          }
                          control={
                            <Checkbox
                              name="termsAndPrivacy"
                              checked={isAgreedToTerms}
                              onChange={handleChangeAgreed}
                              color="info"
                              className={classes.checkBox}
                            />
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} paddingLeft={0}>
                    <AuthSubmitButton
                      type="submit"
                      loading={submitting}
                      disabled={isDisabled}
                      e2e-id="submit"
                    >
                      Register
                    </AuthSubmitButton>
                  </Grid>
                  <Grid item xs={12} className={classes.textContainer}>
                    <Typography className={classes.signupText} component="span">
                      Already have an Account?&nbsp;
                    </Typography>
                    <Typography
                      style={{ color: PaletteColor.TealText, cursor: 'pointer' }}
                      component="span"
                      onClick={() => onLogin(form.reset)}
                    >
                      Log In
                    </Typography>
                  </Grid>
                </Grid>
              </form>
            );
          }}
        </Form>
      </Modal.Content>
    </Modal>
  );
};
